import React, { useCallback, useEffect, useRef, useState } from 'react';
import * as NavigationMenu from '@radix-ui/react-navigation-menu';
import { useRouter } from 'next/router';
import { Divider, SvgIconProps, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import NavigationTab from './NavigationTab';
import { routes, secondaryRoutes } from './routes';
import { useFeatureFlags } from '../../../FeatureFlags';
import Icon, { AvailableIconTypes } from '../../../Icon';
import useIsFirstRender from '../../../../lib/useIsFirstRender';
import useKeyboardListNavigation from '../../utils/useKeyboardListNavigation';
import { KeyMap } from '../../../KeyBinding/utils';

export function getBasePathFromRoute(routePath: string): string {
  return routePath.split('/').slice(0, 3).join('/');
}

export const getTabIcon =
  (iconType: AvailableIconTypes) =>
  ({ className, fontSize }: SvgIconProps) => {
    return <Icon type={iconType} className={className} fontSize={fontSize} />;
  };

const useStyles = makeStyles(theme => ({
  list: {
    padding: ({ isLeftRailOpen }: { isLeftRailOpen: boolean }) =>
      isLeftRailOpen ? '16px 12px' : '16px',
    transition: theme.transitions.create(['padding'], {
      easing: 'cubic-bezier(0, 0, 0.2, 1)',
      duration: 800,
    }),
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
  },
  section: {
    ...theme.mixins.scrollBar,
    overflowY: 'scroll',
    overflowX: 'hidden',
    height: '100%',
    scrollbarGutter: 'stable',
  },
}));

const ExternalLinkslist = ({
  classes,
  isLeftRailOpen,
}: {
  classes: ReturnType<typeof useStyles>;
  isLeftRailOpen: boolean;
}) => {
  const handleConfirmAction = (el: HTMLElement) => {
    const url = el.querySelector('a')?.getAttribute('href');
    if (url) {
      window.open(url, '_blank');
    }
  };

  const handleOnClick = (e: React.MouseEvent) => {
    if (e?.target) {
      handleConfirmAction(e.target as HTMLElement);
    }
  };

  const listRef = useRef<HTMLUListElement>(null);
  const { setRef, onKeyDown, focusedElement } = useKeyboardListNavigation({
    listRef,
    onEnterKeyDown: handleConfirmAction,
    onSpaceKeyDown: handleConfirmAction,
    options: {
      preventDefault: [KeyMap.Space, KeyMap.Enter],
    },
  });

  const { isFeatureEnabled } = useFeatureFlags();
  const secondaryNavigationTabs = secondaryRoutes.filter(item => {
    const { featureFlag } = item;
    if (featureFlag && !isFeatureEnabled(featureFlag)) {
      return false;
    }
    return true;
  });

  return (
    <NavigationMenu.Root>
      <NavigationMenu.List className={classes.list} ref={listRef}>
        {secondaryNavigationTabs.map((tab, index) => (
          <NavigationTab
            key={tab.route}
            label={tab.label}
            value={tab.route}
            Icon={getTabIcon(tab.iconType)}
            isLeftRailOpen={isLeftRailOpen}
            href={tab.route}
            index={index}
            innerRef={setRef(index)}
            tabIndex={focusedElement.index === index ? 0 : -1}
            onKeyDown={onKeyDown}
            onClick={handleOnClick}
            target="_blank"
          />
        ))}
      </NavigationMenu.List>
    </NavigationMenu.Root>
  );
};

const InternalLinkslist = ({
  classes,
  isLeftRailOpen,
}: {
  classes: ReturnType<typeof useStyles>;
  isLeftRailOpen: boolean;
}) => {
  const DEFAULT_ROUTE = routes[0].route;
  const [tabValue, setTabValue] = useState<string>();
  const router = useRouter();
  const route = router ? router.route : '';
  const availableRoutes = routes.map(r => r.route);

  const handleOnClick = (e: React.MouseEvent) => {
    if (e?.target) {
      handleConfirmAction(e.target as HTMLElement);
    }
  };

  const handleConfirmAction = useCallback(
    (el: HTMLElement) => {
      const url = el.querySelector('a')?.getAttribute('href');

      if (url && availableRoutes.includes(url)) {
        setTabValue(getBasePathFromRoute(url));
      }
    },
    [setTabValue, availableRoutes]
  );

  const listRef = useRef<HTMLUListElement>(null);
  const { setRef, onKeyDown, focusedElement } = useKeyboardListNavigation({
    listRef,
    onEnterKeyDown: handleConfirmAction,
    onSpaceKeyDown: handleConfirmAction,
  });

  const isFirstRender = useIsFirstRender();
  useEffect(() => {
    const currentBasePath = getBasePathFromRoute(route ?? DEFAULT_ROUTE);
    setTabValue(currentBasePath);
  }, [setTabValue, isFirstRender, route, DEFAULT_ROUTE]);
  /**
   *
   * If we are already in a project, the studio tab doesn't need to redirect to the studio page
   * so we want to disable the studio tab
   */
  const isTabDisabled = (tabLabel: string) => {
    return (
      tabLabel === 'studio' && router?.route === '/dashboard/studio/[projectId]'
    );
  };

  const { isFeatureEnabled } = useFeatureFlags();
  const mainNavigationTabs = routes.filter(item => {
    const { featureFlag } = item;
    if (featureFlag && !isFeatureEnabled(featureFlag)) {
      return false;
    }
    return true;
  });

  return (
    <NavigationMenu.Root
      defaultValue={tabValue}
      value={tabValue}
      orientation="vertical"
    >
      <NavigationMenu.List className={classes.list} ref={listRef}>
        {mainNavigationTabs.map((tab, index) => (
          <NavigationTab
            key={tab.route}
            label={tab.label}
            onClick={handleOnClick}
            value={tab.route}
            Icon={getTabIcon(tab.iconType)}
            isLeftRailOpen={isLeftRailOpen}
            href={tab.route}
            index={index}
            selected={tabValue === tab.route}
            innerRef={setRef(index)}
            onKeyDown={onKeyDown}
            isTabDisabled={isTabDisabled}
            tabIndex={focusedElement.index === index ? 0 : -1}
          />
        ))}
      </NavigationMenu.List>
    </NavigationMenu.Root>
  );
};

const NavigationTabs = ({
  isLeftRailOpen,
  className,
}: {
  isLeftRailOpen: boolean;
  className: string;
}) => {
  const classes = useStyles({ isLeftRailOpen });

  return (
    <section className={clsx(classes.section, className)}>
      <InternalLinkslist classes={classes} isLeftRailOpen={isLeftRailOpen} />
      <Divider />
      <ExternalLinkslist classes={classes} isLeftRailOpen={isLeftRailOpen} />
    </section>
  );
};
export default NavigationTabs;
