import { Tabs, Tab } from '@material-ui/core';
import NavTab from './NavTab';

function a11yProps(groupId: string = 'nav', index: any) {
  return {
    id: `${groupId}-tab-${index}`,
    'aria-controls': `${groupId}-tabpanel-${index}`,
    role: 'tab',
  };
}

function a11yPropsTabPanel(groupId: string, index: any) {
  return {
    id: `${groupId}-tabpanel-${index}`,
    'aria-labelledby': `${groupId}-tab-${index}`,
    role: 'tabpanel',
  };
}

export { Tabs, Tab, NavTab, a11yProps, a11yPropsTabPanel };
