export enum KeyMap {
  ArrowUp = 'ArrowUp',
  ArrowDown = 'ArrowDown',
  ArrowLeft = 'ArrowLeft',
  ArrowRight = 'ArrowRight',
  Home = 'Home',
  End = 'End',
  Enter = 'Enter',
  Space = 'Space',
  PageUp = 'PageUp',
  PageDown = 'PageDown',
}

export default {
  KeyMap,
};
