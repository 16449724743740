import Tab, { TabProps } from '@material-ui/core/Tab';
import Link from 'next/link';
import React from 'react';

interface NavTabProps extends TabProps<'a'> {
  href: string;
}

const NavTab: React.FC<NavTabProps> = ({ href, ...props }) => (
  <Link href={href} passHref>
    <Tab component="a" {...props} />
  </Link>
);

export default NavTab;
