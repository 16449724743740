import { useRef } from 'react';

/**
 * @description creates a ref on initialization which is set to true initially
 * but then is switched to false
 * @see https://usehooks-ts.com/react-hook/use-is-first-render
 */
export default function useIsFirstRender(): boolean {
  const isFirst = useRef(true);

  if (isFirst.current) {
    isFirst.current = false;

    return true;
  }

  return isFirst.current;
}
