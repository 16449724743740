import React, { useState, useRef } from 'react';
import { KeyMap } from '../../KeyBinding/utils';

interface UseKeyboardListNavigationProps {
  listRef: React.MutableRefObject<HTMLUListElement | HTMLOListElement | null>;
  onEnterKeyDown?: (value: HTMLElement) => void;
  onSpaceKeyDown?: (value: HTMLElement) => void;
  options?: {
    preventDefault?: Array<KeyMap>;
  };
}

/**
 * A hook to allow navigation between a single dimensional list
 */
const useKeyboardListNavigation = ({
  listRef,
  onEnterKeyDown = () => {},
  onSpaceKeyDown = () => {},
  options = {
    preventDefault: [],
  },
}: UseKeyboardListNavigationProps) => {
  const [focusedIndex, setFocusedIndex] = useState<number>(0);
  const refs = useRef<Record<number, HTMLElement>>({});
  const findIndex = (e: HTMLElement) => {
    if (!listRef.current) return -1;
    if (!e) return -1;

    let index = -1;
    listRef.current.childNodes.forEach((node, i) => {
      if (node.contains(e)) {
        index = i;
      }
    });
    return index;
  };

  const onKeyDown = (e: React.KeyboardEvent) => {
    const { key } = e;
    const currentIndex = findIndex(e.target as HTMLElement);
    const size = Object.keys(refs.current).length;
    let newIndex = currentIndex;

    if (options?.preventDefault?.includes(key as KeyMap)) {
      e.preventDefault();
    }

    switch (key) {
      case KeyMap.Space:
        onSpaceKeyDown(refs.current[currentIndex]);
        break;
      case KeyMap.Enter:
        onEnterKeyDown(refs.current[currentIndex]);
        break;
      case KeyMap.Home: {
        refs.current[0]?.focus();
        newIndex = 0;
        break;
      }
      case KeyMap.End: {
        refs.current[size - 1]?.focus();
        newIndex = size - 1;
        break;
      }
      case KeyMap.ArrowDown:
      case KeyMap.ArrowLeft: {
        const nextIndex = (currentIndex + 1) % size;
        refs.current[nextIndex]?.focus();
        newIndex = nextIndex;
        break;
      }
      case KeyMap.ArrowUp:
      case KeyMap.ArrowRight: {
        const prevIndex = (currentIndex - 1 + size) % size;
        refs.current[prevIndex]?.focus();
        newIndex = prevIndex;
        break;
      }

      default:
    }
    setFocusedIndex(newIndex);
  };

  return {
    focusedElement: {
      ref: refs.current[focusedIndex],
      index: focusedIndex,
    },
    onKeyDown,
    setRef: (key: number) => (el: HTMLElement) => {
      refs.current[key] = el;
    },
  };
};

export default useKeyboardListNavigation;
