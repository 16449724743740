import React from 'react';
import useFeatureFlags, { Flags } from './useFeatureFlags';

/**
 * Usage when you want to render something when a feature flag is enabled
 *
 * @example
 * <Feature flag="my-feature-flag" enabled>
 *  <div>My feature flag is enabled</div>
 * </Feature>
 *
 * Usage when you want to render something when a feature flag is not enabled
 *
 * @example
 * <Feature flag="my-feature-flag" not enabled>
 *  <div>My feature flag is not enabled</div>
 * </Feature>
 */
const Feature = (props: {
  flag: Flags;
  // eslint-disable-next-line react/no-unused-prop-types
  enabled: true;
  not?: boolean;
  children: React.ReactNode;
}) => {
  const { flag, not, children } = props;
  const { userFeatureFlags } = useFeatureFlags();

  const hasFlag = userFeatureFlags.includes(flag);

  const shouldRender = not ? !hasFlag : hasFlag;

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>{shouldRender && children}</>
  );
};

export type BehindProps = {
  featureFlag: string;
  children: React.ReactNode;
};

export default Feature;
