import React from 'react';
import {
  Box,
  Tooltip as BaseTooltip,
  makeStyles,
  TooltipProps as BaseTooltipProps,
} from '@material-ui/core';
import clsx from 'clsx';
import Typography from '../Typography';

interface TooltipProps extends Omit<BaseTooltipProps, 'title'> {
  text: string;
  ctaText?: string;
  href?: string;
  onClick?: () => void;
}

const useStyles = makeStyles(theme => ({
  tooltip: {
    '& .MuiTooltip-tooltip': {
      backgroundColor: theme.palette.grey[900],
      color: theme.palette.common.white,
      borderRadius: 0,
      marginBottom: theme.spacing(0.5),
      border: `1px solid ${theme.palette.grey[800]}`,
    },
  },
}));

const Tooltip = (props: TooltipProps) => {
  const {
    children,
    classes: overrideClasses,
    text,
    ctaText,
    href,
    onClick,
    ...restProps
  } = props;
  const classes = useStyles();
  return (
    <BaseTooltip
      interactive
      placement="top-end"
      title={
        <Box>
          <Typography isLegacy variant="inherit">
            {text}
            {ctaText && (
              <a
                href={href}
                target="_blank"
                rel="noopener noreferrer"
                onClick={onClick}
              >
                {ctaText}
              </a>
            )}
          </Typography>
        </Box>
      }
      classes={{
        popper: clsx(classes.tooltip, overrideClasses?.tooltip),
        ...overrideClasses,
      }}
      {...restProps}
    >
      {children}
    </BaseTooltip>
  );
};

export default Tooltip;
