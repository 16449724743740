import React from 'react';
import clsx from 'clsx';
import NextLink from 'next/link';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { SvgIconProps, Link as MuiLink, makeStyles } from '@material-ui/core';
import * as NavigationMenu from '@radix-ui/react-navigation-menu';
import Typography from '../../../Typography';
import { a11yProps } from '../../../Tabs';
import paletteV2 from '../../../Typography/typographyPalette';
import Tooltip from '../../../Tooltip';

const useStyles = makeStyles(theme => ({
  tabIconWrapper: {
    width: theme.spacing(2),
    height: theme.spacing(2),
    backgroundColor: (props: StyleProps) => {
      const { selected } = props;
      return selected ? paletteV2.grey[700] : paletteV2.grey[250];
    },
    borderRadius: '4px',
    padding: (props: StyleProps) => {
      const { selected, isLeftRailOpen } = props;
      const applyPadding = selected && !isLeftRailOpen;
      return applyPadding ? 8 : 0;
    },
  },
  tabWrapper: {
    cursor: 'pointer',
    padding: 8,
    display: 'grid',
    gridAutoFlow: 'column',
    gap: theme.spacing(1.5),
    justifyContent: 'flex-start',
    alignItems: 'center',
    textTransform: 'capitalize',
    fontSize: theme.typography.h5.fontSize,
    borderRadius: '4px',
    textWrap: 'nowrap',
    backgroundColor: (props: StyleProps) => {
      const { selected } = props;
      return selected ? paletteV2.grey[700] : paletteV2.grey[250];
    },
    '&:has(:focus-visible)': {
      ...theme.mixins.focusOutline,
      outlineOffset: -1,
    },
  },
  background: {
    minWidth: theme.spacing(2),
    '&:hover': {
      backgroundColor: (props: StyleProps) => {
        const { selected } = props;
        return selected ? paletteV2.grey[700] : paletteV2.grey[900];
      },
    },
    display: 'inline-block',
    width: 'auto',
  },
  iconContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: 0,
    backgroundColor: 'inherit',
    color: paletteV2.grey[0],
  },
  link: {
    color: paletteV2.grey[200],
    display: 'flex',
    gap: 8,
    '&:focus-visible, &:focus': {
      ...theme.mixins.focusOutline,
      outlineOffset: 0,
    },
  },
  label: {
    opacity: 1,
    visibility: 'visible',
    transition: theme.transitions.create(['opacity', 'visibility'], {
      easing: theme.transitions.easing.easeIn,
      delay: theme.transitions.duration.shortest,
    }),
  },
  hideLabel: {
    opacity: 0,
    visibility: 'hidden',
  },
  tooltip: {
    textTransform: 'capitalize',
  },
}));

interface NavigationTabProps {
  label: string;
  value: string;
  Icon: React.ElementType<SvgIconProps>;
  isLeftRailOpen: boolean;
  href: string;
  index: number;
  innerRef: any;
  // @todo - fix this type
  // | ((instance: HTMLSpanElement | null) => void)
  // | React.RefObject<HTMLSpanElement>
  // | null
  // | undefined;
  tabIndex: number;
  isTabDisabled?: (tabLabel: string) => void;
  onKeyDown?: (event: React.KeyboardEvent) => void;
  onClick?: (event: React.MouseEvent) => void;
  selected?: boolean;
  target?: string;
}

type StyleProps = Pick<NavigationTabProps, 'selected' | 'isLeftRailOpen'>;

type LinkProps = Pick<
  NavigationTabProps,
  | 'selected'
  | 'href'
  | 'isLeftRailOpen'
  | 'label'
  | 'Icon'
  | 'tabIndex'
  | 'onKeyDown'
  | 'target'
> & {
  classes: ClassNameMap<
    'iconContainer' | 'label' | 'hideLabel' | 'link' | 'tabWrapper'
  >;
};

const Link = React.forwardRef<NavigationTabProps['innerRef'], LinkProps>(
  (
    {
      selected,
      href,
      classes,
      isLeftRailOpen,
      label,
      Icon,
      tabIndex,
      onKeyDown,
      target,
    },
    ref
  ) => (
    <NextLink href={href} passHref>
      <MuiLink
        ref={ref}
        tabIndex={tabIndex}
        aria-current={selected ? 'page' : undefined}
        onKeyDown={onKeyDown}
        className={classes.link}
        style={{ textDecoration: 'none' }}
        target={target}
      >
        <div className={classes.iconContainer}>
          <Icon fontSize="default" />
        </div>
        <Typography
          variant="md"
          color="grey-0"
          className={clsx(classes.label, {
            [classes.hideLabel]: !isLeftRailOpen,
          })}
        >
          {label}
        </Typography>
      </MuiLink>
    </NextLink>
  )
);

const NavigationTab = ({
  label,
  value,
  Icon,
  index,
  selected,
  onKeyDown,
  href,
  innerRef,
  tabIndex,
  isLeftRailOpen,
  onClick,
  target = '_self',
}: NavigationTabProps) => {
  const classes = useStyles({ selected, isLeftRailOpen });

  return (
    <Tooltip
      text={label}
      classes={{ tooltip: classes.tooltip }}
      placement="right-end"
      PopperProps={{
        hidden: isLeftRailOpen,
      }}
    >
      <NavigationMenu.Item
        key={value}
        aria-label={label}
        value={value}
        className={clsx(classes.tabWrapper, classes.background)}
        onClick={onClick}
        {...a11yProps(label, index)}
      >
        <Link
          ref={innerRef}
          tabIndex={tabIndex}
          classes={classes}
          label={label}
          href={href}
          selected={selected}
          Icon={Icon}
          isLeftRailOpen={isLeftRailOpen}
          onKeyDown={onKeyDown}
          target={target}
        />
      </NavigationMenu.Item>
    </Tooltip>
  );
};

export default NavigationTab;
