import React from 'react';
import { Drawer, DrawerProps, makeStyles, Theme } from '@material-ui/core';
import clsx from 'clsx';

export const ID_STUDIO_DRAWER_LEFT = 'studio-drawer-left';
export const ID_STUDIO_DRAWER_RIGHT = 'studio-drawer-right';

interface StudioDrawerProps extends DrawerProps {
  /**
   * This flag will reduce the height of the drawer to allow for more space
   * to be utilized for the Upgrade Banner.
   *
   * @prop {boolean = false} withReducedHeight
   */
  withReducedHeight?: boolean;
}

interface StudioDrawerStyleProps
  extends Pick<StudioDrawerProps, 'withReducedHeight'> {}

const useStyles = makeStyles((theme: Theme) => ({
  drawer: {
    // TODO: this needs to be different depending on Drawer
    width: theme.mixins.drawer.width,
    flexShrink: 0,
    [theme.breakpoints.down('xs')]: {
      width: `calc(100vw - 50px)`,
      maxWidth: theme.mixins.drawer.width,
    },
  },
  drawerPaperForAnchorLeft: {
    [theme.breakpoints.down('sm')]: {
      borderRight: `1px solid ${theme.palette.background.paper}`,
      // override spacing for upgrade banner (this is an offcanvas drawer that)
      top: `0 !important`,
    },
    backgroundColor: theme.palette.background.default,
    borderRight: 0,
    display: 'block',
    height: (props: StudioDrawerStyleProps) =>
      props?.withReducedHeight ? `calc(100% - ${theme.spacing(6)}px)` : `100%`,
    top: (props: StudioDrawerStyleProps) =>
      props?.withReducedHeight ? theme.spacing(6) : 0,
    width: theme.mixins.drawer.width,
    '&::-webkit-scrollbar': {
      display: 'none',
      width: '0 !important',
    },
  },
  drawerPaperForAnchorRight: {
    boxSizing: 'border-box',
    padding: theme.spacing(0, 2.5, 0, 2.5),
    borderLeft: `1px solid ${theme.palette.grey[800]}`,
    [theme.breakpoints.up('md')]: {
      top: (props: StudioDrawerStyleProps) =>
        props.withReducedHeight ? theme.spacing(6) : 0,
    },
    [theme.breakpoints.down('md')]: {
      // override spacing for upgrade banner (this is an offcanvas drawer that)
      maxWidth: theme.mixins.drawer.width,
      top: `0 !important`,
      width: `calc(100vw - 50px)`,
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth: theme.mixins.drawer.width,
      width: 'calc(100vw - 50px)',
    },
    backgroundColor: theme.palette.background.paperSecondary,
    width: theme.mixins.drawer.width,
    '&::-webkit-scrollbar': {
      width: '0 !important',
      display: 'none',
    },
  },
}));

const StudioDrawer = (props: StudioDrawerProps) => {
  const {
    anchor,
    onClose,
    children,
    variant,
    withReducedHeight = false,
    className,
    classes: overrideClasses,
    ...restProps
  } = props;

  const classes = useStyles({ withReducedHeight });

  let drawerPaperClass = classes.drawerPaperForAnchorLeft;
  let id = ID_STUDIO_DRAWER_LEFT;

  if (anchor === 'right') {
    drawerPaperClass = classes.drawerPaperForAnchorRight;
    id = ID_STUDIO_DRAWER_RIGHT;
  }

  const paperClass = overrideClasses?.paper;

  return (
    <Drawer
      id={id}
      anchor={anchor}
      classes={{ paper: clsx(drawerPaperClass, paperClass) }}
      className={clsx(className, classes.drawer)}
      onClose={onClose}
      variant={variant}
      {...restProps}
      style={{ position: 'relative', zIndex: 120 }}
    >
      {children}
    </Drawer>
  );
};

export default StudioDrawer;
