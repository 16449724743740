import { makeStyles } from '@material-ui/core';
import { SvgIconProps } from '@material-ui/core/SvgIcon';
import { TabProps } from '@material-ui/core/Tab';
import clsx from 'clsx';
import React from 'react';
import { a11yProps, NavTab } from '../../components/Tabs';
import { paletteV2 } from '../../theme/palette';

const useStyles = makeStyles(theme => ({
  tab: {
    marginRight: 0,
    color: paletteV2.grey[200],
    '&:hover': {
      color: theme.palette.common.white,
    },
    maxWidth: theme.mixins.drawer.width,
    // Using 'unset' because labelIcon from MUI uses 64px by default
    minHeight: 'unset',
    padding: theme.spacing(2, 2, 2, 3),
    '&.Mui-selected': {
      '&.Mui-focusVisible > * > div > div': {
        backgroundColor: theme.palette.contained.hover,
      },
    },
    '&.Mui-focusVisible > * > div': {
      ...theme.mixins.focusOutline,
      borderRadius: 1,
      outlineOffset: theme.spacing(1),
    },
  },
  tabIconWrapper: {
    width: theme.spacing(2),
    height: theme.spacing(2),
    color: (props: DashboardLayoutNavigationDrawerTabProps) => {
      return props.selected ? theme.palette.background.default : 'unset';
    },
    backgroundColor: (props: DashboardLayoutNavigationDrawerTabProps) => {
      return props.selected
        ? theme.palette.primary.main
        : theme.palette.background.default;
    },
    borderRadius: '50%',
    transition: theme.transitions.create(['background-color', 'fill'], {
      duration: 200,
    }),
    padding: 8,
  },
  tabIcon: {
    position: 'absolute',
    top: theme.spacing(1),
    left: theme.spacing(1),
  },
  tabWrapper: {
    display: 'grid',
    gridAutoFlow: 'column',
    gap: theme.spacing(3),
    justifyContent: 'flex-start',
    alignItems: 'center',
    textTransform: 'uppercase',
    fontSize: theme.typography.h5.fontSize,
  },
  tabContainer: {
    position: 'relative',
    overflow: 'hidden',
    marginBottom: `0 !important`,
  },
}));

interface DashboardLayoutNavigationDrawerTabProps extends TabProps<'a'> {
  href: string;
  value: string;
  label: string;
  Icon: React.ElementType<SvgIconProps>;
  a11yLabel: string;
  selected?: boolean;
}

const DashboardLayoutNavigationDrawerTab = (
  props: DashboardLayoutNavigationDrawerTabProps
) => {
  const { href, value, label, Icon, a11yLabel, className, ...restProps } =
    props;
  const classes = useStyles(props);
  return (
    <NavTab
      {...restProps}
      label={label}
      href={href}
      value={value}
      icon={
        <div className={classes.tabContainer}>
          <div className={classes.tabIconWrapper}>
            <Icon className={classes.tabIcon} fontSize="default" />
          </div>
        </div>
      }
      className={clsx(classes.tab, className)}
      classes={{ wrapper: classes.tabWrapper }}
      style={{ fontSize: '14px', fontWeight: 400 }}
      {...a11yProps(a11yLabel, 0)}
    />
  );
};

export default DashboardLayoutNavigationDrawerTab;
