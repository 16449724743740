import { Flags } from '../FeatureFlags';
import { AvailableIconTypes, IconType } from '../Icon/types';

interface DrawerNavigationItem {
  route: string;
  label: string;
  iconType: AvailableIconTypes;
  featureFlag?: Flags;
}

export const routes: Array<DrawerNavigationItem> = [
  {
    route: '/dashboard/projects',
    label: 'projects',
    iconType: IconType.folder,
  },
  {
    route: '/dashboard/studio',
    label: 'studio',
    iconType: IconType.studio,
  },
  {
    route: '/dashboard/avatars',
    label: 'voices',
    iconType: IconType.voices,
  },
  {
    route: '/dashboard/phonetic',
    label: 'pronunciation',
    iconType: IconType.phonetic,
  },
  {
    route: '/dashboard/resources',
    label: 'resources',
    iconType: IconType.playArrow,
  },
  {
    route: '/dashboard/api',
    label: 'api',
    iconType: IconType.codeSquare,
  },
  {
    route: '/dashboard/integrations',
    label: 'integrations',
    iconType: IconType.integrations,
  },
  {
    route: '/dashboard/account',
    label: 'account',
    iconType: IconType.settings,
  },
  {
    route: '/dashboard/admin',
    label: 'admin',
    iconType: IconType.admin,
  },
];

export default { routes };
